import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout.js";
import { Brand } from "../components/pageSections/restaurant-brands";

// markup
const RestaurantBrandsPage = ({
  data,
  pageContext: { originalPath, totalPage, page },
  uri,
}) => {
  const pageNode = data?.page?.nodes[0];
  if (!pageNode) {
    return null;
  }

  return (
    <Layout
      title={pageNode.name}
      description={pageNode.description.description}
      uri={uri}
      image={pageNode.image?.localFile.childImageSharp.original.src}
      imageAlt={pageNode.image?.description}
      mainNav={data.mainNav?.nodes[0]}
      globalNav={data.globalNav?.nodes[0]}
      brands={data.brands.nodes[0].brands}
      footerNav={{
        primary: data.primaryFooterNav?.nodes[0],
        secondary: data.secondaryFooterNav?.nodes[0],
      }}
      originalPath={originalPath}
      headerTitle={pageNode.headerTitle}
      headerBackgroundImage={pageNode.headerBackgroundImage}
      settings={data.settings?.nodes[0]}
    >
      <Brand data={pageNode}></Brand>
    </Layout>
  );
};

export default RestaurantBrandsPage;

export const data = graphql`
  query restaurantBrandsPageQuery($hrefLang: String!, $contentful_id: String!) {
    globalNav: allContentfulNavigation(
      filter: { contentfulid: { eq: "global" }, node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...NavLinks
      }
    }
    mainNav: allContentfulNavigation(
      filter: { contentfulid: { eq: "main" }, node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...NavLinks
      }
    }
    brands: allContentfulPageSectionBrands(
      filter: {
        node_locale: { eq: $hrefLang }
        contentful_id: { eq: "2kjRmkm5mviLHU4RYXdrP6" }
      }
    ) {
      nodes {
        brands {
          name
          slug
        }
      }
    }
    primaryFooterNav: allContentfulNavigation(
      filter: {
        contentfulid: { eq: "footer-primary" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...NavLinks
      }
    }
    secondaryFooterNav: allContentfulNavigation(
      filter: {
        contentfulid: { eq: "footer-secondary" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...NavLinks
      }
    }
    settings: allContentfulSiteSettings(
      filter: { node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...SiteSettings
      }
    }
    page: allContentfulRestaurantBrand(
      filter: {
        contentful_id: { eq: $contentful_id }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        name
        slug
        contentful_id
        instagramLink
        instagramName
        facebookLink
        facebookName
        hideRestaurantBranch
        description {
          description
        }
        backgroundImage {
          description
          gatsbyImageData(layout: FULL_WIDTH, width: 2048, height: 1536)
        }
        michelinImage {
          description
          gatsbyImageData(layout: CONSTRAINED, width: 200, placeholder: NONE)
        }
        transparentLogo {
          description
          gatsbyImageData(layout: CONSTRAINED, width: 320, placeholder: NONE)
        }
        contentBackgroundImage {
          gatsbyImageData(layout: FULL_WIDTH, width: 2048)
        }
        subtitle
        description {
          childMarkdownRemark {
            html
          }
        }
        soupTitle
        soupDescription {
          childMarkdownRemark {
            html
          }
        }
        noodleTitle
        noodleDescription {
          childMarkdownRemark {
            html
          }
        }
        foodImage1 {
          description
          gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: NONE)
        }
        foodImage2 {
          description
          gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: NONE)
        }
        watermarkImage1 {
          description
          gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: NONE)
        }
        watermarkImage2 {
          description
          gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: NONE)
        }
        restaurants {
          name
          slug
          contentful_id
          showDeliveroo
          deliverooLink
          showFoodpanda
          foodpandaLink
          showOpenrice
          openriceLink
          showKeeta
          keetaLink
          location
          hideFromBranches
        }
        darkTheme
        backgroundColor
        subBrands {
          backgroundImage {
            gatsbyImageData(layout: FULL_WIDTH, width: 2048)
          }
          subtitle
          logo {
            description
            gatsbyImageData(layout: CONSTRAINED, width: 320, placeholder: NONE)
          }
          descriptionTitle
          description {
            childMarkdownRemark {
              html
            }
          }
          foodImage {
            description
            gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: NONE)
          }
          restaurants {
            contentful_id
            location
            slug
          }
        }
      }
    }
  }
`;
