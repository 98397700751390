import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import PageContainer from "../pageContainer.js";
import { BsInstagram, BsTriangleFill } from "react-icons/bs";

import noodlesDarkIcon from "../../images/noodles_dark.svg";
import noodlesLightIcon from "../../images/noodles_light.svg";

import soupDarkIcon from "../../images/soup_dark.svg";
import soupLightIcon from "../../images/soup_light.svg";

import likeDarkIcon from "../../images/like_dark.svg";
import likeLightIcon from "../../images/like_light.svg";

import locationDarkIcon from "../../images/location_dark.svg";
import locationLightIcon from "../../images/location_light.svg";

import takeawayDarkIcon from "../../images/takeaway_dark.svg";
import takeawayLightIcon from "../../images/takeaway_light.svg";
import pageBackground from "../../images/page_background.jpg";

import { useLocalization } from "gatsby-theme-i18n";
import Link from "../link.js";
import { FaFacebookF } from "react-icons/fa";
import { SiFoodpanda, SiDeliveroo } from "react-icons/si";
import { theme, when } from "../../styles/theme.js";
import { SectionTitle } from "../typography.js";
import { useInView } from "react-intersection-observer";
import { useState } from "react";

const translations = {
  en: {
    branches: "Branches",
    socialMedia: "Social Media",
    delivery: "Delivery",
  },
  zh: {
    branches: "分店",
    socialMedia: "社交媒體",
    delivery: "外賣平台",
  },
};

const Container = styled.section`
  width: 100vw;
`;

const Header = styled.div`
  background-image: url(${pageBackground});

  ${when("lg")} {
    position: sticky;
    top: -1rem;
    z-index: 100;
  }
`;

const HeaderInnerContainer = styled(PageContainer)`
  padding-top: 4rem;
  padding-bottom: 2rem;

  ${when("lg")} {
    padding-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const HeaderTitle = styled(SectionTitle)``;

const HeaderBrand = styled.div`
  display: none;

  ${when("lg")} {
    font-size: 2rem;
    position: relative;
    width: 20rem;
    text-align: center;
    display: block;
  }
`;

const HeaderBrandArrowUp = styled(BsTriangleFill)`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.colors.red};
`;

const HeaderBrandArrowDown = styled(HeaderBrandArrowUp)`
  transform: rotate(180deg) translateX(50%);
  top: 100%;
  bottom: 0;
  margin-bottom: 0;
  margin-top: 0.5rem;
`;

const BrandsWrapper = styled.div`
  position: relative;
`;

const BrandNameSticky = styled.div`
  position: absolute;
  top: 2rem;
  bottom: 10rem;
  right: 0;
  z-index: 100;
  pointer-events: none;

  ${when("lg")} {
    display: none;
  }
`;

const BrandNameContainer = styled.div`
  position: sticky;
  top: 10rem;
`;

const BrandName = styled.div`
  background: ${(props) => props.theme.colors.gray};
  padding-right: ${(props) => props.theme.pageContainerWidth.xs}rem;
  padding-left: ${(props) => props.theme.pageContainerWidth.xs}rem;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;

  ${when("sm")} {
    padding-right: ${(props) => props.theme.pageContainerWidth.xs}rem;
    padding-left: ${(props) => props.theme.pageContainerWidth.xs}rem;
  }

  ${when("md")} {
    padding-right: ${(props) => props.theme.pageContainerWidth.md}rem;
    padding-left: ${(props) => props.theme.pageContainerWidth.md}rem;
  }
`;

const BrandNameInner = styled.div`
  padding: 0.75rem 1rem;
  display: block;
  font-weight: 500;
`;

const Brands = styled.div`
  width: 100vw;
  overflow-x: hidden;
`;

const BrandContainer = styled.div`
  color: ${(props) => (props.darkTheme ? props.theme.colors.white : "inherit")};
`;

const BrandHeader = styled.div`
  position: relative;
  min-height: 50vh;
  display: flex;
  align-items: center;
`;

const BrandHeaderBackground = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BrandHeaderInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const BrandHeaderLogoContainer = styled.h1`
  padding: 10rem 6rem;
  width: 30rem;
`;
const BrandHeaderLogo = styled(GatsbyImage)``;

const BrandHeaderMichelinImageContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5.5rem;
`;
const BrandHeaderMichelinImage = styled(GatsbyImage)``;

const BrandContent = styled.div`
  position: relative;
`;

const BrandInnerContainer = styled.div`
  position: relative;
  padding-bottom: 5rem;

  ${when("lg")} {
    padding-bottom: 10rem;
  }
`;

const BrandContentContainer = styled.div`
  max-width: 60rem;
  margin: 4rem auto 0;
  padding-right: 5rem;
  //overflow: auto;
  margin-top: 0;
  padding-top: 4rem;

  // ${when("lg")} {
  //   margin-top: 0;
  // }

  ${when("xl")} {
    padding-top: 5rem;
    padding-right: 10rem;
  }
`;

const BrandContentSection = styled.div`
  margin-top: 4rem;
  position: relative;

  &:first-child {
    margin-top: 0;
  }
`;

const BrandContentWatermarkContainer = styled.div`
  position: absolute;
  bottom: 50%;
  right: -5rem;
  z-index: -1;
  width: 18rem;
  margin-right: -${(props) => props.theme.pageContainerWidth.xs}rem;
  overflow-x: hidden;

  ${when("sm")} {
    margin-right: -${(props) => props.theme.pageContainerWidth.sm}rem;
    overflow-x: visible;
  }

  ${when("md")} {
    margin-right: -${(props) => props.theme.pageContainerWidth.md}rem;
  }

  ${when("lg")} {
    bottom: auto;
    top: 5rem;
    left: 100%;
    width: 30rem;
  }

  ${when("xl")} {
    top: 45rem;
    width: 30rem;
  }
`;
const BrandContentWatermark = styled(GatsbyImage)`
  width: 20rem;

  ${when("xl")} {
    width: 30rem;
  }
`;

const BrandContentWatermarkWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const BrandContentWatermarkContainer2 = styled(BrandContentWatermarkContainer)`
  top: 0;
  bottom: auto;

  ${when("lg")} {
    left: auto;
    top: -10rem;
    right: 0;
  }
`;
const BrandContentWatermark2 = styled(BrandContentWatermark)`
  ${when("xl")} {
    width: 20rem;
  }
`;

const BrandContentFoodContainer = styled.div`
  width: 25rem;
  margin-left: -${(props) => props.theme.pageContainerWidth.xs}rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow-x: hidden;
  margin-top: -10rem;

  ${when("sm")} {
    margin-left: -${(props) => props.theme.pageContainerWidth.sm}rem;
  }

  ${when("md")} {
    margin-left: -${(props) => props.theme.pageContainerWidth.md}rem;
  }

  ${when("lg")} {
    margin-left: -${(props) => props.theme.pageContainerWidth.lg}rem;
    overflow-x: visible;
  }

  ${when("xl")} {
    position: absolute;
  }
`;
const BrandContentFoodImage = styled(GatsbyImage)`
  width: 30rem;
`;
const BrandContentFoodDescription = styled.div`
  width: 30rem;
  margin-top: 1rem;
  text-align: center;
`;

const BrandContentFoodContainerAlt = styled(BrandContentFoodContainer)`
  margin-top: 1rem;
  margin-left: auto;
  margin-right: -${(props) => props.theme.pageContainerWidth.xs}rem;
  align-items: flex-start;
  z-index: -1;

  ${when("sm")} {
    margin-right: -${(props) => props.theme.pageContainerWidth.sm}rem;
  }

  ${when("md")} {
    margin-right: -${(props) => props.theme.pageContainerWidth.md}rem;
  }

  ${when("lg")} {
    margin-right: 0;
    position: absolute;
    bottom: 5rem;
    right: 5rem;
    width: 30rem;
  }

  ${when("xl")} {
    top: 16rem;
    bottom: auto;
    margin-right: 0;
    right: 22rem;
  }
`;

const BrandContentFoodImageAlt = styled(BrandContentFoodImage)`
  ${when("xl")} {
    width: 25rem;
  }
`;

const BrandContentFoodDescriptionAlt = styled(BrandContentFoodDescription)`
  ${when("xl")} {
    width: 25rem;
  }
`;

const BrandContentSubtitle = styled.div`
  position: absolute;
  right: ${(props) => props.theme.pageContainerWidth.xs}rem;
  top: 10rem;
  text-align: center;
  writing-mode: vertical-rl;
  text-orientation: upright;
  padding-top: 2rem;
  letter-spacing: 2rem;

  ${when("sm")} {
    right: ${(props) => props.theme.pageContainerWidth.sm}rem;
  }

  ${when("md")} {
    right: ${(props) => props.theme.pageContainerWidth.md}rem;
  }

  ${when("lg")} {
    right: ${(props) => props.theme.pageContainerWidth.lg}rem;
  }
`;

const BrandContentDescriptionTitle = styled.h2`
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 1.17em;
`;

const RestaurantTitle = styled.h3`
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
`;

const BrandContentDescriptionTitleIcon = styled.img`
  display: block;
  margin-right: 1rem;
  width: 3rem;
`;

const BrandContentDescriptionTitleIconNoodles = styled(
  BrandContentDescriptionTitleIcon
)`
  width: 2.5rem;
  margin-right: 1.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-top: 0;
`;

const Button = styled.div`
  padding: 1rem 2rem;
  margin-left: 1rem;
  margin-top: 1rem;
  border: 1px solid
    ${(props) =>
      props.$darkTheme ? props.theme.colors.white : props.theme.colors.black};
  border-radius: 999px;
`;

const BrandContentBackground = styled(GatsbyImage)`
  position: absolute !important;
  left: 0;
  bottom: 0;
  right: 0;
  top: 10rem;
  z-index: -100;

  ${when("xl")} {
    top: 0;
  }
`;

const BrandContentDescription = styled.div`
  margin-top: 1rem;
`;

const BrandContentDescriptionFood = styled(BrandContentDescription)`
  ${when("xl")} {
    max-width: 32rem;
  }
`;

const BrandContentDescriptionIcon = styled(BsTriangleFill)`
  display: inline-block;
  margin-right: 1rem;
`;

const BrandContentSocialMediaLink = styled(Button)`
  background: rgba(255, 255, 255, 0.3);
  border: 2px solid
    ${(props) =>
      props.$darkTheme ? props.theme.colors.white : props.theme.colors.black};
  font-weight: 500;
`;

const SubBrandContainer = styled.div`
  position: relative;
  //z-index: -2;
`;

const SubBrandInnerContainer = styled.div`
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;

  ${when("md")} {
    display: flex;
    align-items: center;
    padding-right: 5rem;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
`;

const SubBrandContent = styled.div`
  max-width: 40rem;
  padding-right: 5rem;
  margin: 3rem auto 0;

  ${when("md")} {
    margin-left: ${(props) => props.theme.gutterWidth.md}rem;
    flex: 1;
  }

  ${when("lg")} {
    margin-left: ${(props) => props.theme.gutterWidth.lg}rem;
  }

  ${when("xl")} {
    max-width: unset;
    flex: 1;
    margin-left: ${(props) => props.theme.gutterWidth.xl}rem;
  }

  ${when("xxl")} {
    margin-left: ${(props) => props.theme.gutterWidth.xxl}rem;
  }
`;

const SubBrandBackground = styled(GatsbyImage)`
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -2;
`;

const SubBrandContentWrapper = styled.div`
  ${when("md")} {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: ${(props) => (props.isEven ? "row" : "row-reverse")};
  }
`;

const SubTitleIcon = styled.img`
  display: block;
  margin-right: 1rem;
  //vertical-align: sub;
  width: 2rem;
  height: 2.5rem;
`;

// const OpenriceIcon = styled.img``;

const SubBrandLogoContainer = styled.div`
  max-width: 16rem;
  margin: 0 auto;

  ${when("md")} {
    max-width: 12rem;
  }

  ${when("lg")} {
    max-width: 14rem;
  }

  ${when("xl")} {
    max-width: 16rem;
  }
`;

const SubBrandLogo = styled(GatsbyImage)``;

const SubBrandFoodImageContainer = styled.div`
  max-width: 20rem;
  margin: 2rem auto 0;

  ${when("md")} {
    max-width: 16rem;
    margin-left: ${(props) => props.theme.gutterWidth.md}rem;
    margin-top: 0;
  }

  ${when("lg")} {
    max-width: 20rem;
    margin-left: ${(props) => props.theme.gutterWidth.lg}rem;
  }

  ${when("xl")} {
    max-width: unset;
    width: 30%;
    margin-left: ${(props) => props.theme.gutterWidth.xl}rem;
  }

  ${when("xxl")} {
    width: 50rem;
    margin-left: ${(props) => props.theme.gutterWidth.xxl}rem;
    padding-left: 10rem;
    padding-right: 10rem;
  }
`;
const SubBrandFoodImage = styled(GatsbyImage)``;

const SubBrandFoodTitle = styled.div`
  text-align: center;
  margin-top: 1rem;
`;

const SubBrandLocationSubtitle = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  writing-mode: vertical-rl;
  text-orientation: upright;
  transform: translateY(-50%);
  white-space: nowrap;
  letter-spacing: 0.5rem;
`;

const SubBrandDescriptionTitle = styled.h3`
  margin: 1rem 0 0;
  display: flex;
  align-items: center;

  ${when("md")} {
    margin-top: 0;
  }
`;
const SubBrandDescription = styled.div`
  margin-top: 1rem;
  max-width: 60rem;
`;

const SubBrandLocationTitle = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
`;

const SubBrandLocations = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -1rem;
`;

const SubBrandRestaurant = styled(Link)`
  padding: 1rem 2rem;
  margin-top: 1rem;
  margin-left: 1rem;
  border: 1px solid
    ${(props) =>
      props.$darkTheme ? props.theme.colors.white : props.theme.colors.black};
  border-radius: 999px;
`;

const InstagramIcon = styled(BsInstagram)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
`;

const FacebookIcon = styled(FaFacebookF)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
`;

// const DeliveryDropdown = styled.div`
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 100%;
//   padding: 0.5rem;
//   background: ${(props) =>
//     props.darkTheme ? "rgba(0, 0, 0, 0.3)" : "rgba(255, 255, 255, 0.3)"};
//   color: ${(props) => (props.darkTheme ? props.theme.colors.white : "inherit")};
//   border-radius: 0.5rem;
//   text-align: center;
//   display: none;
//   z-index: 1000;
// `;

const DeliveryButtonsContainer = styled(ButtonsContainer)`
  margin-left: -2rem;
`;

const DeliveryButton = styled(Button)`
  padding: 0;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 2rem;
`;

const FoodPandaIcon = styled(SiFoodpanda)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
`;

const DeliverooIcon = styled(SiDeliveroo)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
`;

// const DeliveryDropdownLink = styled.a`
//   display: block;
// `;

const BrandPageContainer = styled(PageContainer)`
  //width: 100%;
  flex: 1;

  ${when("lg")} {
    flex: unset;
  }
`;

const OpenRiceIcon = styled.span`
  margin-right: 1rem;

  .gatsby-image-wrapper-constrained {
    display: block;
  }
`;

const SubBrand = ({ data, darkTheme, isEven }) => {
  const { locale } = useLocalization();
  const translation = translations[locale];

  return (
    <SubBrandContainer>
      {data.backgroundImage && (
        <SubBrandBackground
          image={data.backgroundImage.gatsbyImageData}
          alt={""}
          objectFit="cover"
        />
      )}
      <PageContainer>
        <SubBrandInnerContainer>
          {data.subtitle && (
            <SubBrandLocationSubtitle>{data.subtitle}</SubBrandLocationSubtitle>
          )}
          {data.logo && (
            <SubBrandLogoContainer>
              <SubBrandLogo
                image={data.logo.gatsbyImageData}
                alt={data.logo.description}
              />
            </SubBrandLogoContainer>
          )}
          <SubBrandContentWrapper isEven={isEven}>
            {data.foodImage && (
              <SubBrandFoodImageContainer>
                <SubBrandFoodImage
                  image={data.foodImage.gatsbyImageData}
                  alt={data.foodImage.description}
                />
                <SubBrandFoodTitle>
                  <BrandContentDescriptionIcon size={"1.2rem"} />
                  {data.foodImage.description}
                </SubBrandFoodTitle>
              </SubBrandFoodImageContainer>
            )}
            <SubBrandContent>
              {data.descriptionTitle && (
                <SubBrandDescriptionTitle>
                  <BrandContentDescriptionTitleIcon
                    src={darkTheme ? soupLightIcon : soupDarkIcon}
                  />
                  {data.descriptionTitle}
                </SubBrandDescriptionTitle>
              )}
              {data.description && (
                <SubBrandDescription
                  dangerouslySetInnerHTML={{
                    __html: data.description.childMarkdownRemark.html,
                  }}
                />
              )}
              <SubBrandLocationTitle>
                <SubTitleIcon
                  src={darkTheme ? locationLightIcon : locationDarkIcon}
                />
                {translation.branches}
              </SubBrandLocationTitle>
              <SubBrandLocations>
                {data.restaurants.map((restaurant, index) => {
                  return (
                    <SubBrandRestaurant
                      key={index}
                      $darkTheme={darkTheme}
                      href={`/contact-us/${restaurant.slug}`}
                    >
                      {restaurant.location}
                    </SubBrandRestaurant>
                  );
                })}
              </SubBrandLocations>
            </SubBrandContent>
          </SubBrandContentWrapper>
        </SubBrandInnerContainer>
      </PageContainer>
    </SubBrandContainer>
  );
};

export const Brand = ({ data }) => {
  const { locale } = useLocalization();
  const translation = translations[locale];
  // const { ref } = useInView({
  //   onChange: (inView) => {
  //     if (inView) {
  //       setBrand(data.name);
  //     }
  //   },
  //   rootMargin: "-30% 0% -30% 0%",
  // });

  const foodPandas = [];
  const deliveroos = [];
  const openrices = [];
  const keetas = [];

  if (data.restaurants) {
    for (let i = 0; i < data.restaurants.length; i++) {
      const restaurant = data.restaurants[i];
      if (restaurant.foodpandaLink) {
        foodPandas.push({
          name: restaurant.name,
          link: restaurant.foodpandaLink,
        });
      }

      if (restaurant.deliverooLink) {
        deliveroos.push({
          name: restaurant.name,
          link: restaurant.deliverooLink,
        });
      }

      if (restaurant.openriceLink) {
        openrices.push({
          name: restaurant.name,
          link: restaurant.openriceLink,
        });
      }

      if (restaurant.keetaLink) {
        keetas.push({
          name: restaurant.name,
          link: restaurant.keetaLink,
        });
      }
    }
  }

  return (
    <BrandContainer
      darkTheme={data.darkTheme}
      id={data.contentful_id}
      // ref={ref}
    >
      {data.backgroundImage && (
        <BrandHeader>
          <BrandHeaderBackground
            alt={data.backgroundImage.description}
            image={data.backgroundImage.gatsbyImageData}
            objectFit={"cover"}
          />
          <BrandPageContainer>
            <BrandHeaderInnerContainer>
              {data.transparentLogo && (
                <BrandHeaderLogoContainer>
                  <BrandHeaderLogo
                    alt={data.transparentLogo.description}
                    image={data.transparentLogo.gatsbyImageData}
                  />
                </BrandHeaderLogoContainer>
              )}
              {data.michelinImage && (
                <BrandHeaderMichelinImageContainer>
                  <BrandHeaderMichelinImage
                    alt={data.michelinImage.description}
                    image={data.michelinImage.gatsbyImageData}
                  />
                </BrandHeaderMichelinImageContainer>
              )}
            </BrandHeaderInnerContainer>
          </BrandPageContainer>
        </BrandHeader>
      )}

      {data.contentBackgroundImage && (
        <BrandContent>
          <BrandContentBackground
            alt={""}
            image={data.contentBackgroundImage.gatsbyImageData}
          />
          <PageContainer>
            <BrandInnerContainer>
              {data.subtitle && (
                <BrandContentSubtitle>{data.subtitle}</BrandContentSubtitle>
              )}
              {data.foodImage1 && (
                <BrandContentFoodContainer>
                  <BrandContentFoodImage
                    alt={data.foodImage1.description}
                    image={data.foodImage1.gatsbyImageData}
                  ></BrandContentFoodImage>
                  <BrandContentFoodDescription>
                    <BrandContentDescriptionIcon size={"1.2rem"} />
                    {data.foodImage1.description}
                  </BrandContentFoodDescription>
                </BrandContentFoodContainer>
              )}
              <BrandContentContainer>
                {data.description && (
                  <BrandContentSection>
                    {data.watermarkImage1 && (
                      <BrandContentWatermarkContainer>
                        <BrandContentWatermark
                          alt={data.watermarkImage1.description}
                          image={data.watermarkImage1.gatsbyImageData}
                        ></BrandContentWatermark>
                      </BrandContentWatermarkContainer>
                    )}
                    <BrandContentDescription
                      dangerouslySetInnerHTML={{
                        __html: data.description.childMarkdownRemark.html,
                      }}
                    />
                  </BrandContentSection>
                )}
                {data.soupTitle && data.soupDescription && (
                  <BrandContentSection>
                    <BrandContentDescriptionTitle>
                      <BrandContentDescriptionTitleIcon
                        src={data.darkTheme ? soupLightIcon : soupDarkIcon}
                      />
                      {data.soupTitle}
                    </BrandContentDescriptionTitle>
                    <BrandContentDescriptionFood
                      dangerouslySetInnerHTML={{
                        __html: data.soupDescription.childMarkdownRemark.html,
                      }}
                    />
                  </BrandContentSection>
                )}
                {data.noodleTitle && data.noodleDescription && (
                  <BrandContentSection>
                    <BrandContentDescriptionTitle>
                      <BrandContentDescriptionTitleIconNoodles
                        src={
                          data.darkTheme ? noodlesLightIcon : noodlesDarkIcon
                        }
                      />
                      {data.noodleTitle}
                    </BrandContentDescriptionTitle>
                    <BrandContentDescriptionFood
                      dangerouslySetInnerHTML={{
                        __html: data.noodleDescription.childMarkdownRemark.html,
                      }}
                    />
                  </BrandContentSection>
                )}
                {!data.hideRestaurantBranch && (
                  <BrandContentSection>
                    <RestaurantTitle>
                      <SubTitleIcon
                        src={
                          data.darkTheme ? locationLightIcon : locationDarkIcon
                        }
                      />
                      {translation.branches}
                    </RestaurantTitle>
                    <ButtonsContainer>
                      {data.restaurants.map((restaurant, index) => {
                        if (
                          !restaurant.location ||
                          restaurant.hideFromBranches
                        ) {
                          return null;
                        }
                        return (
                          <Button
                            key={index}
                            as={Link}
                            $darkTheme={data.darkTheme}
                            href={`/contact-us/${restaurant.slug}`}
                          >
                            {restaurant.location}
                          </Button>
                        );
                      })}
                    </ButtonsContainer>
                  </BrandContentSection>
                )}
                {data.watermarkImage2 && (
                  <BrandContentWatermarkWrapper>
                    <BrandContentWatermarkContainer2>
                      <BrandContentWatermark2
                        alt={data.watermarkImage2.description}
                        image={data.watermarkImage2.gatsbyImageData}
                      />
                    </BrandContentWatermarkContainer2>
                  </BrandContentWatermarkWrapper>
                )}
                {(data.instagramLink || data.facebookLink) && (
                  <BrandContentSection>
                    <RestaurantTitle>
                      <SubTitleIcon
                        src={data.darkTheme ? likeLightIcon : likeDarkIcon}
                      />
                      {translation.socialMedia}
                    </RestaurantTitle>
                    <ButtonsContainer>
                      {data.facebookLink && data.facebookName && (
                        <BrandContentSocialMediaLink
                          as={"a"}
                          href={data.facebookLink}
                          target={"_blank"}
                          $darkTheme={data.darkTheme}
                        >
                          <FacebookIcon />
                          {data.facebookName}
                        </BrandContentSocialMediaLink>
                      )}
                      {data.instagramLink && data.instagramName && (
                        <BrandContentSocialMediaLink
                          as={"a"}
                          href={data.instagramLink}
                          target={"_blank"}
                          $darkTheme={data.darkTheme}
                        >
                          <InstagramIcon />
                          {data.instagramName}
                        </BrandContentSocialMediaLink>
                      )}
                    </ButtonsContainer>
                  </BrandContentSection>
                )}
                {(foodPandas.length > 0 ||
                  deliveroos.length > 0 ||
                  openrices.length > 0 ||
                  keetas.length > 0) && (
                  <BrandContentSection>
                    <RestaurantTitle>
                      <SubTitleIcon
                        src={
                          data.darkTheme ? takeawayLightIcon : takeawayDarkIcon
                        }
                      />
                      {translation.delivery}
                    </RestaurantTitle>
                    <DeliveryButtonsContainer>
                      {foodPandas.length > 0 && (
                        <DeliveryButton
                          $darkTheme={data.darkTheme}
                          as={Link}
                          href={`/contact-us${
                            data.restaurants && data.restaurants.length
                              ? `/${data.restaurants[0].slug}`
                              : ""
                          }`}
                        >
                          <FoodPandaIcon />
                          FoodPanda
                        </DeliveryButton>
                      )}
                      {deliveroos.length > 0 && (
                        <DeliveryButton
                          $darkTheme={data.darkTheme}
                          as={Link}
                          href={`/contact-us${
                            data.restaurants && data.restaurants.length
                              ? `/${data.restaurants[0].slug}`
                              : ""
                          }`}
                        >
                          <DeliverooIcon />
                          Deliveroo
                        </DeliveryButton>
                      )}
                      {openrices.length > 0 && (
                        <DeliveryButton
                          $darkTheme={data.darkTheme}
                          as={Link}
                          href={`/contact-us${
                            data.restaurants && data.restaurants.length
                              ? `/${data.restaurants[0].slug}`
                              : ""
                          }`}
                        >
                          <OpenRiceIcon>
                            {data.darkTheme ? (
                              <StaticImage
                                src="../../images/openrice_light.png"
                                alt=""
                                width={16}
                                height={16}
                                placeholder={"none"}
                              />
                            ) : (
                              <StaticImage
                                src="../../images/openrice_dark.png"
                                alt=""
                                width={16}
                                height={16}
                                placeholder={"none"}
                              />
                            )}
                          </OpenRiceIcon>
                          Openrice
                        </DeliveryButton>
                      )}
                      {keetas.length > 0 && (
                        <DeliveryButton
                          $darkTheme={data.darkTheme}
                          as={Link}
                          href={`/contact-us${
                            data.restaurants && data.restaurants.length
                              ? `/${data.restaurants[0].slug}`
                              : ""
                          }`}
                        >
                          {data.darkTheme ? (
                            <StaticImage
                              src="../../images/keetaWhite.png"
                              alt=""
                              width={64}
                              height={32}
                              placeholder={"none"}
                            />
                          ) : (
                            <StaticImage
                              src="../../images/keetaBlack.png"
                              alt=""
                              width={64}
                              height={32}
                              placeholder={"none"}
                            />
                          )}
                        </DeliveryButton>
                      )}
                    </DeliveryButtonsContainer>
                  </BrandContentSection>
                )}
              </BrandContentContainer>
              {data.foodImage2 && (
                <BrandContentFoodContainerAlt>
                  <BrandContentFoodImageAlt
                    alt={data.foodImage2.description}
                    image={data.foodImage2.gatsbyImageData}
                  ></BrandContentFoodImageAlt>
                  <BrandContentFoodDescriptionAlt>
                    <BrandContentDescriptionIcon size={"1.2rem"} />
                    {data.foodImage2.description}
                  </BrandContentFoodDescriptionAlt>
                </BrandContentFoodContainerAlt>
              )}
            </BrandInnerContainer>
          </PageContainer>
          {data.subBrands &&
            data.subBrands.map((subBrand, index) => {
              return (
                <SubBrand
                  data={subBrand}
                  key={index}
                  darkTheme={data.darkTheme}
                  isEven={index % 2 !== 0}
                />
              );
            })}
        </BrandContent>
      )}
    </BrandContainer>
  );
};

const Restaurants = ({ data }) => {
  const [brand, setBrand] = useState(null);
  return (
    <Container>
      <Header>
        <HeaderInnerContainer>
          <HeaderTitle color={theme.colors.red}>{data.title}</HeaderTitle>
          {brand && (
            <HeaderBrand>
              <HeaderBrandArrowUp size={"1rem"}></HeaderBrandArrowUp>
              {brand}
              <HeaderBrandArrowDown size={"1rem"}></HeaderBrandArrowDown>
            </HeaderBrand>
          )}
        </HeaderInnerContainer>
      </Header>
      <BrandsWrapper>
        {brand && (
          <BrandNameSticky>
            <BrandNameContainer>
              <BrandName>
                <BrandNameInner>{brand}</BrandNameInner>
              </BrandName>
            </BrandNameContainer>
          </BrandNameSticky>
        )}
        <Brands>
          {data.brands.map((brand, index) => {
            return <Brand data={brand} key={index} setBrand={setBrand} />;
          })}
        </Brands>
      </BrandsWrapper>
    </Container>
  );
};

export const RestaurantsName = "ContentfulPageSectionRestaurants";

export default Restaurants;
